<template>
  <v-container fluid tag="section">

        <v-dialog  v-model="alert_msg"  width="500">

           <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Mensagem
        </v-card-title>

        <v-card-text>
            {{msg_retorno}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="alert_msg = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>


      
      
       </v-dialog>


<base-material-card
      icon="mdi-bell"
      title="Cadastro de Notificação"
      class="px-5 py-3"
    >
    <v-form ref="form">
        <v-card-text>
            <v-container>
              <v-row>
             
                  <v-col cols="6">
                    <v-text-field  outlined  clearable  maxlength="30"
                      placeholder="Titulo" v-model="notificacao.titulo">
                    </v-text-field>
                  </v-col>

                  <v-col cols="6" md="6">
                    <v-select outlined
                      v-model="notificacao.pagina"
                      :items="selectOptions"
                      label="Página"
                    ></v-select>
                  </v-col>
        
              <v-col cols="12">
                <v-text-field  outlined  clearable  maxlength="80"
                  placeholder="Mensagem " v-model="notificacao.mensagem">
                </v-text-field>
              </v-col>

              <v-col cols="6" md="6">
                <v-select outlined
                  v-model="notificacao.tipo"
                  :items="selectTipos"
                  label="Tipo"
                ></v-select>
              </v-col>
             

                 
              </v-row>
              

            </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="success"  :loading="btn_senha"  @click="salva()" >Salvar
            <v-icon right
               dark>
               mdi-check
             </v-icon>
        </v-btn>

        </v-card-actions>
        </v-form>  
</base-material-card>



  </v-container>


</template>

<script>
  import Notificacao from '../../services/notificacao'

export default {

  created() {
          if(this.$store.state.auth.status.loggedIn == false){
           this.$router.push('/')
          }
  },

  data () {
      return {
        dlg: true,
        btn_senha: false,

        selectOptions: ["/Inicio","/Resumo", "/Campanha", "/Contrato", "/Óbitos", "/Sepultamento", "/Auditoria", "/Cobranca", "/Receita", "/inadimplencia", "/Venda", "/Cancelamento", "/Acordos"],
        selectTipos: ["Novidade", "Correção", "Atualização"],


        notificacao: {
            titulo: '',
            mensagem: '',
            pagina: '',
        },
        name: 'Register',
        senhaAtual: '',
        senhaNova: '',
        msg_retorno: '',
        alert_msg: false
      }
  },
  methods: {

    salva(){
      Notificacao.criar_notificacao(this.notificacao)
          .then(response => {
                    if (response.status === 200) {
                      this.alert_msg = true;
                      this.msg_retorno = "Cadastrado com sucesso!"
                    }
                  })
                  .catch(error => {
                    // Lida com erros
                    console.error('Erro ao baixar o arquivo Excel:', error);
                  }) .finally(() => {
                    // Após o término do download, oculte o diálogo de progresso
                    this.notificacao = {
                        titulo: '',
                        mensagem: '',
                        pagina: '',
                        tipo: '',
                    };
                    this.showProgress = false;
                  });
      },
     
  }


}
</script>
